import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '../redux/user/Slice';
import { useNavigate } from 'react-router-dom';
import { selectOrganisationData } from '../redux/organisation/Selector';

const useCustomAxiosCallTwo = () => {
    const organisation = useSelector(selectOrganisationData);
    const orgId = organisation?.organisationData?.encrypt_id;
    const ADMIN_BASE_URL = process.env.REACT_APP_EKYAM_ADMIN_BASE_URL;
    const CONFIG_BASE_URL = process.env.REACT_APP_EKYAM_CONFIG_MAPPER_BASE_URL;
    const WORKFLOW_BASE_URL = process.env.REACT_APP_EKYAM_WORKFLOW_BASE_URL;
    const COLLECTIVEDATA_BASE_URL = process.env.REACT_APP_EKYAM_COLLECTIVE_DATA_BASE_URL;
    const LOGGER_SERVICE_BASE_URL = process.env.REACT_APP_EKYAM_LOGGER_BASE_URL;

    const accessToken = (typeof window !== 'undefined' && window) && window.localStorage.getItem('access_token');
    const dispatch = useDispatch();
    const navigate = useNavigate()

    // can be moved under the user auth hook
    const logoutUser = () => {
        window.localStorage.removeItem('access_token');
        dispatch(clearUser());
        navigate('/');
    };
    const getDefaultHeaders = () => ({
        'Authorization': `Bearer ${ accessToken }`,
        'x-organisation-id': orgId && orgId,
    });

    const callAxiosApi = ({
        uriEndPoint = {
            url: '', method: 'GET', service: '', params:{}
        },
        body,
    }) => {
        let baseURL = ADMIN_BASE_URL
        switch (uriEndPoint.service) {
            case 'collectivedata':
                baseURL = COLLECTIVEDATA_BASE_URL
                break;
            case 'logger':
                baseURL = LOGGER_SERVICE_BASE_URL
                break
            case 'config':
                baseURL = CONFIG_BASE_URL
                break;
            case 'workflow':
                baseURL = WORKFLOW_BASE_URL
                break;
            default:
                break;
        }
        const API_URL = `${ baseURL }${ uriEndPoint.url }`;
        const customHeader = uriEndPoint.headers ? uriEndPoint.headers:{}
        return Axios({
            method: uriEndPoint.method,
            url: API_URL,
            headers: accessToken && { ...getDefaultHeaders(), ...customHeader },
            data: body,
            params: uriEndPoint?.params,
            responseType: uriEndPoint.responseType,
        });
    };

    const callApi = ({
        uriEndPoint = {
            url: '', method: 'GET', service: '', params: {}
        },
        body,
    }) => callAxiosApi({
        uriEndPoint,
        body
    }).then((resp) => resp)
        .catch((err) => {
            console.log('ERROR:', err?.response);
            if (err?.response?.data?.statusCode === 403) {
                logoutUser();
            }
            return err?.response;
        });

    return {
        callApi,
        getDefaultHeaders,
    };
};

export default useCustomAxiosCallTwo;