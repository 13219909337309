// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aiAgentPopup_modalOverlayWrapper__VB7oa {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-transparent-black);
    backdrop-filter: blur(1px);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aiAgentPopup_modalOverlay__uIgzD {
    padding: 1.6rem 2rem;
    width: 35%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--color-white);
    overflow: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px var(--color-transparent-black);
}

.aiAgentPopup_buttonContainer__Z9uka {
    display: flex;
    gap: 15px;
    margin: 20px 0 0 15px;
}

.aiAgentPopup_buttonClose__g-dQH {
    border: none;
    font-size: 10px;
    font-weight: 400;
    padding: 8px 30px;
    border-radius: 4px;
    color: #000000CC;
    background: #C8C8C8;
}

.aiAgentPopup_innerContent__7LeHL {
    box-shadow: 0px 2.32px 17.29px 0px #0000002B;
    padding: 1rem;
    font-size: 18px;
    border-left: 2.44px solid #4470EC;
    border-radius: 9px;
    margin-bottom: 1rem;
}
.aiAgentPopup_details__ANtvV {
    margin-top: 1.5rem;
}
.aiAgentPopup_header__87Wt7 p {
    text-transform: capitalize !important;
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/AIAgentPopup/aiAgentPopup.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,gDAAgD;IAChD,0BAA0B;IAC1B,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,UAAU;IACV,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,8BAA8B;IAC9B,cAAc;IACd,mBAAmB;IACnB,mDAAmD;AACvD;;AAEA;IACI,aAAa;IACb,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,4CAA4C;IAC5C,aAAa;IACb,eAAe;IACf,iCAAiC;IACjC,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,qCAAqC;AACzC","sourcesContent":[".modalOverlayWrapper {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: var(--color-transparent-black);\r\n    backdrop-filter: blur(1px);\r\n    z-index: 999;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.modalOverlay {\r\n    padding: 1.6rem 2rem;\r\n    width: 35%;\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    background: var(--color-white);\r\n    overflow: auto;\r\n    border-radius: 10px;\r\n    box-shadow: 0 0 10px var(--color-transparent-black);\r\n}\r\n\r\n.buttonContainer {\r\n    display: flex;\r\n    gap: 15px;\r\n    margin: 20px 0 0 15px;\r\n}\r\n\r\n.buttonClose {\r\n    border: none;\r\n    font-size: 10px;\r\n    font-weight: 400;\r\n    padding: 8px 30px;\r\n    border-radius: 4px;\r\n    color: #000000CC;\r\n    background: #C8C8C8;\r\n}\r\n\r\n.innerContent {\r\n    box-shadow: 0px 2.32px 17.29px 0px #0000002B;\r\n    padding: 1rem;\r\n    font-size: 18px;\r\n    border-left: 2.44px solid #4470EC;\r\n    border-radius: 9px;\r\n    margin-bottom: 1rem;\r\n}\r\n.details {\r\n    margin-top: 1.5rem;\r\n}\r\n.header p {\r\n    text-transform: capitalize !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlayWrapper": `aiAgentPopup_modalOverlayWrapper__VB7oa`,
	"modalOverlay": `aiAgentPopup_modalOverlay__uIgzD`,
	"buttonContainer": `aiAgentPopup_buttonContainer__Z9uka`,
	"buttonClose": `aiAgentPopup_buttonClose__g-dQH`,
	"innerContent": `aiAgentPopup_innerContent__7LeHL`,
	"details": `aiAgentPopup_details__ANtvV`,
	"header": `aiAgentPopup_header__87Wt7`
};
export default ___CSS_LOADER_EXPORT___;
