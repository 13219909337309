import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    agents: {}, // Store multiple agents in an array
    isOpenChatbot: false,
};

const AiAgents = createSlice({
    name: 'aiAgents',
    initialState,
    reducers: {
        setAgents: (state, action) => {
            state.agents = {
                ...state.agents,
                [ action.payload.workflowId ]: action.payload.agents
            };
        },
        clearAgents: (state) => {
            state.agents = [];
        },
        setOpenChatbot: (state) => {
            state.isOpenChatbot = true;
        },
        setCloseChatbot: (state) => {
            state.isOpenChatbot = false;
        },
    },
});

export const AiAgentReducer = AiAgents.reducer;
export const { setAgents, clearAgents, setOpenChatbot, setCloseChatbot } = AiAgents.actions;
