import React from 'react';
import Button from '../button/Button.jsx';
import Typography from '../typography/Typography.jsx';
import styles from './aiAgentPopup.module.css';

function AIAgentPopup({ isOpen, onClose, data, name = '' }) {
    const handleOutsideModalClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e);
        }
    };
    if (!isOpen) return null;
    return (
        <div
            className={styles.modalOverlayWrapper}
            onClick={handleOutsideModalClick}
        >
            <div className={styles.modalOverlay}>
                <div className={styles.header}>
                    <Typography variant="title">{name}</Typography>
                </div>
                <div className={styles.details}>
                    {data.map((item, index) => (
                        <div className={styles.innerContent} key={index}>
                            {item.name}
                        </div>
                    ))}
                </div>
                <div className={styles.buttonContainer}>
                    <Button className={styles.buttonClose} onClick={onClose}>
                        Close
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default AIAgentPopup;
