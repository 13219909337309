import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const LineChart = ({ data = [], height, colors }) => {

    const chartRef = useRef(null);
    const [ dimensions, setDimensions ] = useState({ width: 0 });

    useEffect(() => {
        const handleResize = () => {
            if (chartRef.current) {
                const width = chartRef.current.offsetWidth;
                setDimensions({ width });
            }
        };

        handleResize();
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        if (!chartRef.current || dimensions.width === 0 || data.length === 0)
            return;

        d3.select(chartRef.current).select('svg').remove();

        const { width } = dimensions;
        const margin = { top: 20, right: 30, bottom: 50, left: 50 };
        const svg = d3
            .select(chartRef.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${ margin.left },${ margin.top })`);

        const chartWidth = width - margin.left - margin.right;
        const chartHeight = height - margin.top - margin.bottom;

        const x = d3
            .scaleBand()
            .domain(data[ 0 ]?.values.map((d) => d.Month) || [])
            .range([ 0, chartWidth ])
            .padding(0.2);

        const y = d3
            .scaleLinear()
            .domain([ 0, 100 ])
            .nice()
            .range([ chartHeight, 0 ]);

        svg.append('g')
            .attr('transform', `translate(0,${ chartHeight })`)
            .call(d3.axisBottom(x));

        svg.append('g').call(
            d3
                .axisLeft(y)
                .ticks(5)
                .tickFormat((d) => `${ d }%`)
        );

        const line = d3
            .line()
            .x((d) => x(d.Month) + x.bandwidth() / 2)
            .y((d) => y(d.value))
            .curve(d3.curveMonotoneX);

        data.forEach((category, i) => {
            svg.append('path')
                .datum(category.values)
                .attr('fill', 'none')
                .attr('stroke', colors[ i % colors.length ])
                .attr('stroke-width', 2)
                .attr('d', line);

            svg.selectAll(`.dot-${ i }`)
                .data(category.values)
                .enter()
                .append('circle')
                .attr('cx', (d) => x(d.Month) + x.bandwidth() / 2)
                .attr('cy', (d) => y(d.value))
                .attr('r', 4)
                .attr('fill', 'black');
        });
    }, [ dimensions, data, colors, height ]);

    return <div ref={chartRef} style={{ width: '100%' }} />;
};

export default LineChart;
