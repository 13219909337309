import React from 'react';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import Table from '../../../commonComponents/table/Table.jsx';
import styles from './orders.module.css';
import { useOrders } from '../functionality/orders.func';

const Orders = ({ orderType='total', tribe='webshop', title='' }) => {

    const { data,
        columns,
        loading, error,
        currentPage,
        setCurrentPage,totalRecords,
        setPageSize, displayTitle  } = useOrders({ url:'/webshop-orders-detail-view', service: 'collectivedata' }, orderType, tribe);
    return <div>
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <Typography variant='title'>{title?title:displayTitle}</Typography>
            </div>
            {(!loading && error) ?
                <div className={styles.heading}><Typography variant='error'>{error}</Typography></div>:
                <Table
                    loading={loading}
                    headerList={columns}
                    totalRecords={totalRecords}
                    setPageSize={setPageSize}
                    rowsData={data}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            }
        </div>
    </div>
}

export default Orders;