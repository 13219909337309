import React, { useEffect, useMemo } from 'react';
import styles from './writerConnect.module.css'
import ConfigMapperFieldV2 from '../../../views/ConfigMapperV2/layout/ConfigMapperFieldV2.jsx'
import useConfigMapperField from '../../../views/configMapper/functionality/useConfigMapperFeild.func';
import useFormStep1 from '../../../components/formStep/functionality/useFormStep1.func';
import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func';
import configData from '../../../dummyData/ConfigWriter.json'
import AppLoader from '../../../appLoader';
import { useSelector } from 'react-redux';
import { workflowData } from '../../../redux/workflow/Selector';
import useWriterConnect from '../functionality/writerConnect.func';

function WriterConnect() {
    const { verifyConnectivityConfig, validationLoading, id }=useWriterConnect()
    const workflow = useSelector(workflowData);
    const getWorkflow = workflow?.length>0 && workflow?.find(item => item[ id ]);
    const workFlowData = getWorkflow[ id ];
    const { convertToNested, flattenJSON } = useConfigMapperField();
    const { updateCustomFieldConfigJson } = useConfigMapperFieldV2();
    const { prepareFormData, handleFormValidation, checkNestedObjectEmpty } = useFormStep1();

    const [ formValues, setFormValues ] = React.useState({ ...flattenJSON({ tribe: 'webshop', system: 'shopify' }) })
    const [ validationError, setValidationError ] = React.useState({})
    const [ fieldData, setFieldData ] = React.useState([ ...JSON.parse(JSON.stringify(configData)) ])

    const inputChangeHandler = (event) => {
        if (event?.target?.name) {
            setFormValues(prevState => {
                let newObj = prevState;
                newObj[ event?.target?.name ] = event?.target?.value
                return newObj
            })
        }

    }

    const formSubmitHandler = (event) => {
        event.preventDefault()
        const formElements = event.target.elements;
        let finalFormData = prepareFormData(formElements, fieldData, formValues);
        let nestedFormData = convertToNested(finalFormData);
        nestedFormData.tribe = workFlowData.tribe;
        nestedFormData.system = workFlowData.system;
        let validationErrObj = handleFormValidation(configData, nestedFormData, setValidationError)
        if (checkNestedObjectEmpty(validationErrObj)) {
            verifyConnectivityConfig(nestedFormData, setValidationError)
            return
        }
    }
    const fetchedData = useMemo(() => ({
        endpoints: {
            [ workFlowData.endpoints ]: '',
        },
        system: workFlowData.system,
        tribe: workFlowData.tribe,
        base_url: '',
    }), [ workFlowData ]);

    useEffect(() => {
        setFormValues({ ...flattenJSON({ ...fetchedData }) })
        setFieldData([ ...JSON.parse(JSON.stringify(configData)) ])
        updateCustomFieldConfigJson(fieldData, setFieldData, fetchedData, { ...flattenJSON({ ...fetchedData }) })
    }, [ fetchedData ])

    return (
        <AppLoader>
            <form className={`${ styles.formWrapper }`} onSubmit={formSubmitHandler} >
                <div className={styles.inputFieldsWrapper}>
                    {fieldData?.map((fieldRow, index) => {
                        return <div key={index} className={`${ styles.row } ${ styles[ `col-${ fieldRow?.columnsCount || 3 }` ] }`}>
                            {fieldRow?.data?.map(inputData =>
                                <ConfigMapperFieldV2
                                    fieldData={inputData}
                                    fieldListData={fieldData}
                                    key={inputData?.field_name}
                                    formData={formValues}
                                    setFieldData={setFieldData}
                                    onInputChange={inputChangeHandler}
                                    validationError={validationError}
                                    setValidationError={setValidationError}
                                />
                            )}
                        </div>
                    })}
                    <div className={styles.formSubmitWrapper}>
                        <input type='submit' className={styles.primary} value={validationLoading ? 'Verifying' : 'Verify'} disabled={validationLoading} />
                    </div>
                </div>
            </form>
        </AppLoader>
    )
}

export default WriterConnect;