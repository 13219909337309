import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { setUser } from '../../../redux/user/Slice';
import validateFieldData from '../validateFieldData'
import { setActionId, setHideModal, settutorial } from '../../../redux/visitor/Slice';
import { toast } from 'react-toastify';

export const useLoginfFormFunc = () => {
    const [ formData, setFormData ] = useState({
        username: '',
        password: ''
    })
    const [ loading, setLoading ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ validate, setValidate ] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { callApi } = useCustomAxiosCall();

    useEffect(() => {
        if (validate) {
            validateFieldData(formData,setErrorMessage);
        }
    }, [ formData, validate ]);

    const handleLogin = async () => {
        setValidate(true)
        const finalCheck = validateFieldData(formData, setErrorMessage);
        if(!finalCheck){
            setLoading(true);
            const response = await callApi({
                uriEndPoint: {
                    url: '/auth/login',
                    method: 'POST',
                },
                body: formData
            });
            const responseData = response?.data;
            if (responseData?.message === 'USER-LOGIN-SUCCESS') {
                setLoading(false);
                if (typeof window !== 'undefined' && window) {
                    window.localStorage.setItem('access_token', responseData?.data?.access_token)
                }
                dispatch(setUser(responseData));
                dispatch(settutorial(responseData?.data?.person?.is_first_time_visited))
                if (responseData?.data?.person?.email === 'demo@ekyam.ai' || responseData?.data?.person?.is_first_time_visited){
                    dispatch( setActionId('welcome'))
                }
                dispatch(setHideModal(false));
                toast.success('Login successful', { position: 'top-right', style: { textTransform: 'capitalize' } })
                navigate('/organisation');
            } else {
                setLoading(false);
                if (responseData?.msg==='INVALID_USERNAME_PASSWORD'){
                    setErrorMessage({ msg:'Invalid username and password' });
                }else{
                    toast.error('Something went wrong')
                }
            }
        }
    }
    const handleClearUsername = () => {
        setFormData({ ...formData, username: '' });
    }
    const handleTogglePassword = () => {
        setShowPassword(prevState => !prevState);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [ name ]: value });
    }

    return {
        formData,
        loading,
        showPassword,
        errorMessage,
        handleLogin,
        handleClearUsername,
        handleTogglePassword,
        handleChange
    }
}