const validateField = (fieldName, value, formData ) => {
    const fieldErrors = [];
    switch (fieldName) {
        case 'password':
        case 'confirmPassword':
            if (!value) {
                const has = fieldName[ 0 ].toUpperCase() + fieldName.slice(1);
                fieldErrors.push(`${ has } is required`);
            } else if (!value.trim()) {
                const has = fieldName[ 0 ].toUpperCase() + fieldName.slice(1);
                fieldErrors.push(`${ has } is required.`);
            } else if (
                ((fieldName === 'firstName' || fieldName === 'lastName') && value.length < 3) || value.length > 16) {
                fieldErrors.push('Length should between 3 - 16');
            } else if (
                (fieldName === 'password' && value.length < 5) || value.length > 16 ) {
                fieldErrors.push('Length should between 5 - 16');
            } else if ( fieldName === 'confirmPassword' && value !== formData[ 'password' ] ) {
                fieldErrors.push('Password do not match.');
            }
            break;
        case 'email':
            if (!value) {
                fieldErrors.push('This Field is required');
            } else if (!/^\S+@\S+\.\S+$/.test(value)) {
                fieldErrors.push('Provide a valid email');
            }
            break;
        default:
            break;
    }
    return fieldErrors;
};
export default validateField;