import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './user';
import organisationReducer from './organisation';
import { dashboardReducer } from './dashbaord/Slice';
import sidebarReducer from './sidebar';
import notificationReducer from './notification';
import shipNoticeReducer from './shipNotice';
import collectiveDataReducer from './collectiveData';
import tutorialReducer from './visitor';
import workflowReducer from './workflow';
import AiAgentReducer from './AiAgentchatbot';

export const rootReducer = combineReducers({
    user: userReducer,
    organisation:organisationReducer,
    dashboard: dashboardReducer,
    sidebar:sidebarReducer,
    shipNotice:shipNoticeReducer,
    collectiveData:collectiveDataReducer,
    notification:notificationReducer,
    tutorial: tutorialReducer,
    aiAgents: AiAgentReducer,
    workflow: workflowReducer
});