import React  from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganisationData } from '../../../redux/organisation/Selector';
import { addNotification } from '../../../redux/notification/Slice';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { formatDate } from '../../../helpers';
const Notifications = ()=>{
    const organisation = useSelector(selectOrganisationData);
    const orgId = organisation?.organisationData?.id;
    const dispatch = useDispatch();
    const access_token = window.localStorage.getItem('access_token');
    const NOTIFY_BASE_URL = process.env.REACT_APP_EKYAM_NOTIFY_BASE_URL
    const connectionId = uuidv4(); // Generate a unique connection ID
    const ENDPOINT = `wss://${ NOTIFY_BASE_URL }/ws/${ orgId }/${ connectionId }`;

    const handleConnectSocket = () => {
        const socket = new WebSocket(ENDPOINT);
        socket.onmessage = (event) => {
            const message = JSON.parse(event.data)
            console.log('MESSAGE', message);
            if(message){
                dispatch(addNotification({ name:message?.body?.message, id:message?.body?.id, time:formatDate(Date.now()), read:false }));
                toast(message?.body?.message)
            }
        }
        socket.onclose = () => handleConnectSocket()
    }

    const clearConnection = async () => {
        try {
            console.log('clear connection');
            await axios.post(`https://${ NOTIFY_BASE_URL }/clear-connection/${ orgId }/${ connectionId }`);
        } catch (error) {
            console.error('Error clearing connection:', error);
        }
    };
    useEffect(() => {
        if(!access_token || !orgId) return;
        console.log('hello if');
        handleConnectSocket();
        window.addEventListener('beforeunload', clearConnection);
        return () => {
            console.log('hello if clear')
            clearConnection()
            window.removeEventListener('beforeunload', clearConnection);
        };
    }, []);

    return <ToastContainer
        position='bottom-right'
        newestOnTop
        limit={6}/>
}
export default Notifications;