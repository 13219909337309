import apple from './apple.png';
import autoPilotOff from './autoPilotOff.png';
import autoPilotOn from './autoPilotOn.png';
import avatar from './Avatar.png';
import bkFolderIcon from './bkFolderIcon.png';
import blackClock from './blackClock.png';
import blueClock from './blueClock.png';
import facebook from './facebook.png';
import folder from './folder.png';
import folderIcon from './folderIcon.png';
import google from './google.png';
import headerLogo from './headerLogo.png';
import iconCard from './iconCard.png';
import logo from './logo.png';
import mainImage from './mainImage.png';
import netsuit from './netsuit.png';
import secondaryImage from './secondaryImage.png';
import system from './system.png';
import shopifyImage from './shopify.png';
import productImage from './proImage.png';
import aiIcon from './ai_icon.png'
const assets = {
    apple,
    autoPilotOff,
    autoPilotOn,
    avatar,
    bkFolderIcon,
    blackClock,
    blueClock,
    facebook,
    folder,
    folderIcon,
    google,
    headerLogo,
    iconCard,
    logo,
    mainImage,
    netsuit,
    secondaryImage,
    system,
    shopifyImage,
    productImage,
    aiIcon
};

export default assets;