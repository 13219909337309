import React from 'react';
import styles from './deleteDialog.module.css';
import PopupWrapper from '../popupWrapper/PopupWrapper.jsx';

const DeleteDialog = ( { handleDelete, deleteDialog, setDeleteDialog } ) => {
    console.log(deleteDialog, 'deleteDialog12')
    const onClose = (e) => {
        e.stopPropagation();
        setDeleteDialog(false)
    }

    return (
        <>
            {deleteDialog.isOpen && <PopupWrapper isOpen={open} onClose={onClose} height={'25vh'} width={'32%'}>
                <div className={styles.modal}>
                    <p>Do you want to delete &quot;{deleteDialog.name}&quot;?</p>
                    <div className={styles.modalActions}>
                        <button className={styles.confirmBtn} onClick={(e) => { e.stopPropagation(); handleDelete(deleteDialog.name); }}>Yes</button>
                        <button className={styles.cancelBtn} onClick={(e) => { e.stopPropagation(); onClose(e) }}>No</button>
                    </div>
                </div>
            </PopupWrapper>}
        </>
    );
}

export default DeleteDialog;
