import React from 'react';
import AppLoader from '../../../appLoader';
import styles from './queueTracker.module.css';
import revenue from '../../../dummyData/QueueTracker.json';
import StatCounter from '../../../components/widgets/statCounter/layout/StatCounter.jsx';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import Table from '../../../commonComponents/table/Table.jsx';
import DialogWrapper from '../../../commonComponents/DialogWrapper/DialogWrapper.jsx';
import Spinner from '../../../commonComponents/spinner/Spinner.jsx';
import { useQueuTrackerFunc } from '../functionality/QueueTracker.func';

function QueueTacker({ workflowId = '' }) {
    const { queue } = revenue;
    const {
        open,
        modalData,
        queuesStats,
        headerList,
        dialogueHeadersList,
        options,
        loading,
        taskWithIDs,
        tasksList,
        openDropdown,
        dropdownRef,
        rowClickHandler,
        handleRetry,
        handleToggleStatus,
        handleCloseDropdown,
        onClose,
        setCurrentPage,
        totalCount
    } = useQueuTrackerFunc(workflowId);
    const dropdown = openDropdown && (
        <div
            ref={dropdownRef}
            className={styles.dropDownWrapper}
            style={{ top: openDropdown.y, left: openDropdown.x }}
        >
            {options.map((option, index) => {
                // Check if the option should be disabled
                const isDisabled =
                    [ 'completed', 'cancelled' ].includes(openDropdown.id.status) &&
                    [ 'Retry', 'Cancel', 'Pause', 'Resume' ].includes(option);

                return (
                    <div
                        key={index}
                        className={`${ styles.dropDownItem } ${ isDisabled ? styles.disabled : '' }`}
                        onClick={() => {
                            if (!isDisabled) {
                                if (option === 'Retry') {
                                    handleRetry(openDropdown.id);
                                } else if (option === 'Cancel') {
                                    handleToggleStatus(openDropdown.id, 'cancelled');
                                } else if (option === 'Pause') {
                                    handleToggleStatus(openDropdown.id, 'paused');
                                } else if (option === 'Resume') {
                                    handleToggleStatus(openDropdown.id, 'in_progress');
                                }
                                handleCloseDropdown();
                            }
                        }}
                    >
                        {option}
                    </div>
                );
            })}
        </div>

    );

    // Dialog logic // Nam echange
    const gridData = (
        <>
            <div className={styles.tableContainer}>
                <div className={styles.tableHeader}>
                    {!workflowId.length>0 &&
                    <Typography variant="header-700">{queue.heading}</Typography>
                    }
                </div>
                <Table
                    headerList={headerList}
                    rowsData={taskWithIDs}
                    totalRecords={totalCount}
                    isShowAllHeader={false}
                    props={{
                        onRowClick: rowClickHandler,
                    }}
                    setCurrentPage={setCurrentPage}
                />
            </div>
            {dropdown}
        </>
    );

    // Main content
    const content = (
        <div className={styles.mainWrapper}>
            {loading ? (
                <Spinner height={'10vh'} />
            ) : (
                <>
                    <div className={styles.widgets}>
                        {queuesStats.map((obj, index) => (
                            <StatCounter
                                key={index}
                                data={{ stat_name: obj.name, count: obj.count || 0, icon: obj.icon }}
                            />
                        ))}
                    </div>
                    <DialogWrapper isOpen={open} onClose={onClose}>
                        {
                            modalData?.data_flow_type === 'real_time' ?

                                <div className={styles.modalContainer}>
                                    {
                                        modalData?.error && <>

                                            <div className={styles.errorSection}>
                                                <p className={styles.errorTitle}>Error Message:</p>
                                                <p className={styles.errorMessage}>{modalData.error?.msg}</p>
                                            </div>
                                            <div className={styles.tracebackSection}>
                                                <p className={styles.tracebackTitle}>Error Traceback:</p>
                                                <pre className={styles.tracebackContent}>{modalData.error?.traceback}</pre>
                                            </div>
                                        </>
                                    }
                                    <div className={styles.dataSection}>
                                        <p className={styles.dataTitle}>Data:</p>
                                        <pre className={styles.dataContent}>{JSON.stringify(modalData?.data, null, 2)}</pre>
                                    </div>
                                </div>
                                : (
                                    <Table
                                        headerList={dialogueHeadersList}
                                        rowsData={modalData.queues?.map((i, index) => ({ ...i, id: index }))}
                                        totalRecords={tasksList.length}
                                        isShowAllHeader={false}
                                    />
                                )
                        }
                    </DialogWrapper>
                    {gridData}
                </>
            )}
        </div>
    );

    // Conditional rendering based on workflowId
    return (
        <>
            {typeof workflowId === 'string' && workflowId.trim().length > 0 ? gridData : <AppLoader>{content}</AppLoader>}
        </>
    );
}

export default QueueTacker;
