import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styles from './dateDropdown.module.css';
import LucideIcon from '../lucideicon/LucideIcon.jsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../button/Button.jsx';
import { convertDate } from '../../helpers/index';

const DateDropDown = ({ id = '', options = [], defaultValue = '', defaultOption, setIsFilter, customsSyles = {}, className = '', bodyClassName = '', handleDate ,businessLogicRef }) => {
    const [ isOpen, setOpen ] = useState(false);
    const [ startDate, setStartDate ] = useState(null);
    const [ endDate, setEndDate ] = useState(null);

    const toggleDropdown = () => { setOpen(true) };

    const btnRef = useRef()
    const closeDropdown = () => {
        setOpen(false);
    }
    useEffect(() => {
        const handleScroll = () => {
            setOpen(false); // Close all dropdowns when scrolling
        };

        const businessLogicElement = businessLogicRef?.current;
        if (businessLogicElement) {
            businessLogicElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (businessLogicElement) {
                businessLogicElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    const handleFilter=()=>{
        const stateDateValue=convertDate(startDate || new Date());
        const endDateValue=convertDate(endDate  || new Date());
        if(stateDateValue && stateDateValue)
            setIsFilter({ start_date: stateDateValue , end_date: endDateValue , filter:true });
        handleDate({ start_date: stateDateValue, end_date: endDateValue });
        setStartDate(null);
        setEndDate(null);
        closeDropdown();
    }

    // eslint-disable-next-line react/display-name
    const CustomDateInput = forwardRef(({ value, onClick, placeholder }, ref) => (
        <div className={styles.customDateInput} onClick={onClick} ref={ref}>
            <input
                type="text"
                value={value}
                placeholder={placeholder}
                readOnly
                className={styles.dateInput}
            />
            <LucideIcon name={'CalendarDays'} className={styles.icon}/>
        </div>
    ));
    const getDefaultOption=()=>{
        return(
            <div className={styles.groupByDate}><LucideIcon size="16" name='Clock' className={styles.clock}/>{defaultOption} <LucideIcon name={isOpen ? 'ChevronUp': 'ChevronDown'} size="14" className={styles.icon}/></div>
        )
    }
    const handleClickOutside = (event) => {
        if (btnRef.current && !btnRef.current.contains(event.target)) {
            closeDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div id={id} className={`${ styles.dropdown } ${ className }`} ref={btnRef} style={customsSyles} key={defaultOption ? defaultOption : options.find(item => item.value === defaultValue)?.name || 'Default'}>
            <div className={styles.dropdownHeader}  onClick={toggleDropdown} >
                {getDefaultOption()}
            </div>
            <div className={`${ styles.dropdownBody } ${ isOpen && styles.open } ${ bodyClassName }`}>
                <h3 className={styles.title}>Absolute Time Range</h3>
                <div className={styles.dateInputs}>
                    <div className={styles.datePickerContainer}>
                        <label className={styles.label}>From</label>
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select start date"
                            className={styles.datePicker}
                            customInput={<CustomDateInput />}
                            maxDate={new Date()}
                        />
                    </div>
                    <div className={styles.datePickerContainer}>
                        <label className={styles.label}>To</label>
                        <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select end date"
                            minDate={startDate}
                            className={styles.datePicker}
                            customInput={<CustomDateInput />}
                            maxDate={new Date()}
                        />
                    </div>
                </div>
                <Button className={styles.button} onClick={handleFilter}>Apply Time Range</Button>
            </div>
        </div>
    )
}

export default DateDropDown;