import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import validateFieldData from '../functionality/validateFieldData';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../redux/user/Slice';
// import { setOrganisation } from '../../../redux/organisation/Slice';

export const useSignupForm = () => {
    const [ searchParams ] = useSearchParams();
    const [ formData, setFormData ] = useState({
        email:'', password:'',system:''
    })
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(()=>{
        setFormData({
            email: searchParams.get('email'),
            password: '',
            system:searchParams.get('system') || 'shopify'
        })
    }, [])
    const [ loading, setLoading ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ validate, setValidate ] = useState(false);
    const { callApi } = useCustomAxiosCall();
    useEffect(() => {
        if (validate) {
            validateFieldData(formData,setErrorMessage);
        }
    }, [ formData, validate ]);

    const handleLogin = async () => {
        setValidate(true)
        const finalCheck = validateFieldData(formData, setErrorMessage);
        console.log(formData, 'formData')
        if(!finalCheck){
            setLoading(true);
            const response = await callApi({
                uriEndPoint: {
                    url: '/signup',
                    method: 'POST',
                    service:'ekyam-app'
                },
                body: formData
            });
            const responseData = response?.data;
            console.log(responseData, 'response')
            if (responseData?.message === 'USER-SIGNUP-SUCCESS') {
                setLoading(false);
                if (typeof window !== 'undefined' && window) {
                    window.localStorage.setItem('access_token', responseData?.tokens?.data?.access_token)
                }
                dispatch(setUser(responseData?.tokens));
                toast('User Registered Successfully');
                navigate('/admin-dashboard')
            } else {
                setLoading(false);
                toast.error('Something went wrong')
            }
        }
    }
    const handleClearUsername = () => {
        setFormData({ ...formData, email: '' });
    }
    const handleTogglePassword = () => {
        setShowPassword(prevState => !prevState);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [ name ]: value });
    }

    return {
        formData,
        loading,
        showPassword,
        errorMessage,
        handleLogin,
        handleClearUsername,
        handleTogglePassword,
        handleChange
    }
}