import { useState } from 'react';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';

export const useChatBot = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ messages, setMessages ] = useState([
        { text: 'Hello! I\'m Ekyam Bot. How can I assist you today?', user: false },
        { text: 'Ekyam is a leading platform providing solutions for identity management and security.', user: false },
        { text: 'Would you like to know more about our services or need help with something specific?', user: false },
    ]);
    const [ input, setInput ] = useState('');
    const { callApi } = useCustomAxiosCall();

    const toggleChatBot = () => {
        setIsOpen(!isOpen);
    };

    const processInput = async (inputText) => {
        try {
            const response = await callApi({
                uriEndPoint: {
                    url: '/get_input',
                    method: 'POST',
                    service: 'chat_gpt',
                },
                body: { prompt_body: inputText },
            });

            const responseData = response?.data?.response;
            console.log('Response from get_input API:', responseData);
            if (responseData && Array.isArray(responseData)) {
                return responseData.map(item => formatDataObject(item)).join('<br /><br />');
            }
            else if (responseData && typeof responseData === 'object') {
                return formatDataObject(responseData);
            }

            return 'No data found.';

        } catch (error) {
            console.error('Error processing input:', error);
            return "I'm sorry, but I couldn't process your request. Please try again.";
        }
    };

    const formatDataObject = (obj) => {
        let formattedString = '';

        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const value = obj[ key ];
                const formattedKey = key.replace(/_/g, ' ');

                if (Array.isArray(value)) {
                    formattedString += `<strong>${ formattedKey }</strong>:<br />`;
                    value.forEach(item => {
                        formattedString += formatDataObject(item) + '<br />';
                    });
                }

                else if (typeof value === 'object' && value !== null) {
                    formattedString += `<strong>${ formattedKey }</strong>: <br />${ formatDataObject(value) }<br />`;
                }

                else {
                    formattedString += `<strong>${ formattedKey }</strong>: ${ value || 'N/A' }<br />`;
                }
            }
        }

        return formattedString;
    };

    const sendMessage = async () => {
        if (input.trim() === '') return;

        const userMessage = { text: input, user: true };
        setMessages((prevMessages) => [ ...prevMessages, userMessage ]);
        setInput('');

        let responseText = await processInput(input);

        if (!responseText) {
            responseText = "I'm sorry, but I couldn't process your request. Please try again.";
        }

        const botMessage = { text: responseText, user: false };
        setMessages((prevMessages) => [ ...prevMessages, botMessage ]);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    };

    return {
        isOpen,
        toggleChatBot,
        messages,
        input,
        setInput,
        sendMessage,
        handleKeyDown,
    };
};
