import React, { useEffect, useMemo, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import styles from './fieldMapping.module.css';
import AppLoader from '../../../appLoader';
import Button from '../../../commonComponents/button/Button.jsx';
import { useConfigWriter } from '../functionality/configWriter.func';
import { capitalizeCamelCaseWords } from '../../../helpers/index';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { workflowData } from '../../../redux/workflow/Selector';
import systemPayload from '../../../dummyData/configWriterSystem.json'

const ConfigWriter = () => {
    const { id } = useParams();
    const workflow = useSelector(workflowData);
    const getWorkflow = workflow?.length > 0 && workflow?.find(item => item[ id ]);
    const data = getWorkflow?.[ id ]

    const { ekyamStandard, handleSubmit } = useConfigWriter(data)
    const [ variationOne, setVariationOne ] = useState({
        data: ekyamStandard || {},
        value: {},
    });
    const [ variationTwo, setVariationTwo ] = useState({
        data: systemPayload || {},
        value: {},
    });
    const [ isValidJsonOne, setIsValidJsonOne ] = useState({
        true: '',
        false: '',
    });
    const [ isValidJsonTwo, setIsValidJsonTwo ] = useState({
        true: '',
        false: '',
    });
    const [ systemData, setSystemData ] = useState()
    useEffect(() => {
        setVariationOne({ ...variationOne, data: ekyamStandard })
    }, [ ekyamStandard ])
    const variationOnePlaceholder = useMemo(() => variationOne.data, [ variationOne.data ]);
    const variationTwoPlaceholder = useMemo(() => variationTwo.data, [ variationTwo.data ]);

    useEffect(() => {
        if (Object.keys(variationTwo.value).length > 0 || Object.keys(variationTwo.data).length > 0) {
            const transformData = (data) => {
                if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
                    return Object.fromEntries(
                        Object.entries(data).map(([ key, value ]) => [ key, transformData(value) ])
                    );
                } else if (Array.isArray(data)) {
                    return data.map(transformData);
                } else if (typeof data === 'string') {
                    return `$${ data }$`;
                }
                return data;
            };

            const parseJson = (json) => {
                try {
                    return JSON.parse(json);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    return null;
                }
            };

            const parsedValue = Object.keys(variationTwo.value).length > 0
                ? parseJson(variationTwo.value)
                : (variationTwo.data);
            if (parsedValue) {
                const transformedData = transformData(parsedValue);
                setSystemData(transformedData);
            }
        }
    }, [ variationTwo ]);

    return (
        <AppLoader>
            <div className={styles.rulesContent}>
                <div className={styles.textFieldWrapper}>
                    <div className={styles.outputContainer}>
                        <div className={styles.ruleHeader}>
                            {capitalizeCamelCaseWords(data?.sourceData?.system)} {capitalizeCamelCaseWords(data?.sourceData?.service)}
                        </div>
                        <div className={styles.textfield}>
                            <JSONInput
                                placeholder={variationOnePlaceholder}
                                locale={locale}
                                height="500px"
                                width="100%"
                                onChange={(e) => {
                                    const jsonValue = e?.json || '';
                                    setVariationOne((prev) => ({ ...prev, value: jsonValue }));
                                    setIsValidJsonOne({ true: 'Valid JSON', false: '' });

                                }}
                                style={{
                                    body: {
                                        fontSize: '14px',
                                    },
                                    labelColumn: {
                                        fontSize: '14px',
                                    },
                                    valueColumn: {
                                        fontSize: '14px',
                                    },
                                }}
                                theme="light_mitsuketa_tribute"
                                errorStyle={{ fontSize: '14px' }}
                            />
                            {isValidJsonOne.false && (
                                <div style={{ color: 'red', marginTop: '10px' }}>{isValidJsonOne.false}</div>
                            )}
                            {isValidJsonOne.true && (
                                <div style={{ color: 'green', marginTop: '10px' }}>{isValidJsonOne.true}</div>
                            )}
                        </div>
                    </div>
                    <div className={styles.inputContainer}>
                        <div className={styles.ruleHeader}>System Payload</div>
                        <div className={styles.textfield}>
                            <JSONInput
                                placeholder={variationTwoPlaceholder}
                                locale={locale}
                                height="500px"
                                width="100%"
                                onChange={(e) => {
                                    const jsonValue = e?.json || '';
                                    setVariationTwo((prev) => ({ ...prev, value: jsonValue }));
                                    setIsValidJsonTwo({ true: 'Valid JSON', false: '' });
                                }}
                                style={{
                                    body: { fontSize: '14px' },
                                    labelColumn: { fontSize: '14px' },
                                    valueColumn: { fontSize: '14px' },
                                }}
                                theme="light_mitsuketa_tribute"
                                errorStyle={{ fontSize: '14px', color: 'red' }}
                            />
                            {isValidJsonTwo.false && (
                                <div style={{ color: 'red', marginTop: '10px' }}>{isValidJsonTwo.false}</div>
                            )}
                            {isValidJsonTwo.true && (
                                <div style={{ color: 'green', marginTop: '10px' }}>{isValidJsonTwo.true}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.buttonWrapper}>
                <Button className={styles.saveAndCloseButton} onClick={() => { handleSubmit(systemData, data) }}>Save</Button>
                <Button className={styles.button}>Close</Button>
            </div>
        </AppLoader>
    );
};

export default ConfigWriter;