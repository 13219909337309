import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styles from './businessLogic.module.css'

import PopupWrapper from '../../popupWrapper/PopupWrapper.jsx';
import Input from '../../input/Input.jsx';
import Typography from '../../typography/Typography.jsx';
import Button from '../../button/Button.jsx';
import DropDownTwo from '../../dropdownTwo/DropdownTwo.jsx';
import { MenuItem, Select } from '@mui/material';
import LucideIcon from '../../lucideicon/LucideIcon.jsx';
import DateDropDown from '../../dateDropdown/DateDropdown.jsx';
import DatePicker from 'react-datepicker';
import businessLogicData from '../../../dummyData/BusinessLogic.json'
import { useBusinessLogicFunc } from '../functionality/businessLogic.func';

function BusinessLogicPopup({ isOpen, onClose, height, width, handleOperation, businessLogic, setBusinessLogic, workflowData, error, index }) {
    console.log(index, 'indexdfg', businessLogic)
    const { addLogicToConditions, isOpenDropdowns, setIsOpenDropdowns, toggleDropdown, handleAddClick, handleChange, handleRemoveClick, handleClose, initialState } = useBusinessLogicFunc({ setBusinessLogic, businessLogic, onClose });
    const { operations, logics, businessLogics } = businessLogicData
    const dropdownRefs = useRef({});
    const businessLogicRef = useRef(null);
    const [ showMenu, setShowMenu ] = useState(false);
    const [ isFilter, setIsFilter ] = useState({
        start_date: 'dd/mm/yyyy',
        end_date: 'dd/mm/yyyy'
    });
    function separateBusinessLogicAndOperation(data) {
        const { business_logic, operation } = data;
        return {
            business_logic,
            operation,
        };
    }
    useEffect(() => {
        const handleScroll = () => {
            setIsOpenDropdowns({}); // Close all dropdowns when scrolling
        };

        const businessLogicElement = businessLogicRef.current;
        if (businessLogicElement) {
            businessLogicElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (businessLogicElement) {
                businessLogicElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (workflowData && workflowData?.flows?.length > 0 && workflowData?.flows[ index ] && isOpen===true) {
            const dataaa = separateBusinessLogicAndOperation(workflowData?.flows[ index ]);
            const fixedData = {
                business_logic: dataaa?.business_logic && Object.keys(dataaa?.business_logic)?.length > 0 ? addLogicToConditions(dataaa?.business_logic) : initialState?.business_logic,
                operation: dataaa?.operation || []
            }
            setBusinessLogic(fixedData)
        }
    }, [ isOpen, index, workflowData ])

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.values(dropdownRefs.current).every(
                (ref) => ref && !ref.contains(event.target)
            );
            if (isOutside) {
                setIsOpenDropdowns({});
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSave = (isMapping) => {
        handleOperation(isMapping)
    }

    // eslint-disable-next-line react/display-name
    const CustomDateInput = forwardRef(({ value, onClick, placeholder }, ref) => (
        <div className={styles.datepickerContainer} onClick={onClick} ref={ref}>
            <input
                type="text"
                value={value}
                placeholder={placeholder}
                readOnly
                className={styles.datepicker}
            />
            <LucideIcon name={'CalendarDays'} className={styles.icon} />
        </div>
    ));
    const handleDate = (data, index, item) => {
        handleChange('value', data, index, item.method)
    }
    const handleDateOperation =(index, item)=>{
        return(
            (item.operator === 'between' ?
                <DateDropDown options={[]} className={styles.select} onDropDownChange={() => { }} setIsFilter={setIsFilter} defaultOption={isFilter?.start_date && isFilter?.end_date ? `${ isFilter?.start_date } - ${ isFilter?.end_date }` : 'dd/mm/yyyy - dd/mm/yyyy'} handleDate={(data) => handleDate(data, index, item)} dropdownRefs={dropdownRefs} businessLogicRef={businessLogicRef} />
                :
                <DatePicker
                    selected={item.value}
                    onChange={date => handleChange('value', date, index, item.method)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/MM/yyyy"
                    className={styles.select}
                    customInput={<CustomDateInput />}
                    maxDate={new Date()}
                />))
    }
    if (!isOpen) return null;
    const overlayStyles = {};
    height ? overlayStyles.height = height : null;
    width ? overlayStyles.width = width : null;
    return (
        <PopupWrapper isOpen={isOpen} onClose={handleClose} height={height} width={width}>
            <div className={styles.businessLogicWrapper}>
                <div className={styles.checkboxOuter}>
                    <div className={styles.title}>
                        <Typography variant='title'>Operation</Typography>
                        <Button className={styles.saveAndCloseButton} onClick={()=>handleSave(true)}>Mapping</Button>
                    </div>
                    <div className={styles.checkboxContainer}>
                        {operations?.map((item) =>
                            <div key={item?.name} className={styles.checkboxWrapper}>
                                <Input type="checkbox" checked={businessLogic?.operation?.includes(item?.value)} className={styles.checkbox} onChange={() => handleChange('operation', item?.value)} />
                                <span className={styles.checkboxName}>{item?.name}</span>
                            </div>
                        )}
                    </div>
                    <div className={`${ styles.errorMessage } ${ error.operation && styles.showErrorMessage }`}> {error.operation || 'empty'} </div>
                </div>
                <div className={styles.businessLogicWrapper}>
                    <Typography variant='title'>Business Logic</Typography>
                    <div className={styles.businessLogic} ref={businessLogicRef}>
                        {businessLogic?.business_logic?.conditions?.map((item, index) => (
                            <div key={index} className={styles.wrapper}>
                                {index !== 0 &&
                                <div className={styles.andOrSelect}>
                                    <Select
                                        labelId="demo-select-small-label"
                                        className={styles.select}
                                        id="demo-select-small"
                                        name="logic"
                                        displayEmpty
                                        onChange={(e) => {
                                            handleChange('logic', e.target.value, index)
                                        }}
                                        renderValue={
                                            item?.logic ? () => (item?.logic?.split('_')?.join(' ')) : () => <div className={styles.conditionPlaceholder}>{logics[ 0 ].value}</div>
                                        }
                                        MenuProps={{
                                            styles: {
                                                paper: styles.paper,
                                            },
                                        }}
                                        inputProps={{
                                            styles: {
                                                icon: styles.icon,
                                            },
                                        }}
                                    >
                                        {logics.map((ele) =>
                                            <MenuItem key={ele.name} value={ele.name}>{ele.name}</MenuItem>
                                        )}
                                    </Select>
                                </div>}
                                <div className={styles.bussinessLogicContent}>
                                    <div style={{ display: 'flex' }}>
                                        <Input
                                            type='text'
                                            placeholder="key"
                                            value={item.key}
                                            name='name'
                                            onChange={(e) => handleChange('key', e?.target?.value, index)}
                                            className={styles.keySelect}
                                        />
                                        <div className={styles.logicHeading}>
                                            <div className={styles.dropdownOne}>
                                                <button
                                                    onClick={() => toggleDropdown(index)}
                                                    className={styles.dropdown}
                                                >
                                                    {item.operator || 'Logic'}
                                                    <LucideIcon name="ChevronDown" />
                                                </button>

                                                {isOpenDropdowns[ index ] && (
                                                    <div
                                                        ref={(el) => (dropdownRefs.current[ index ] = el)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0px',
                                                            left: '0',
                                                            transform: 'translate(162px, 350px)',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #ccc',
                                                            zIndex: '1000',
                                                            maxHeight: '250px',
                                                            overflowY: 'auto',
                                                        }}
                                                    >
                                                        {businessLogics?.map((category, ind) => (
                                                            <div key={ind} style={{ padding: '10px' }}>
                                                                <div style={{ fontWeight: 'bold', color: '#4A90E2', marginBottom: '5px' }}>{category?.label}</div>
                                                                {category?.subOptions.map((option, idx) => (
                                                                    <div
                                                                        key={idx}
                                                                        style={{
                                                                            padding: '5px 10px',
                                                                            cursor: 'pointer',
                                                                            borderRadius: '4px',
                                                                        }}
                                                                        onClick={() => handleChange('operator', option, index, category?.operation)}
                                                                        onMouseOver={(e) => (e.target.style.backgroundColor = '#f0f0f0')}
                                                                        onMouseOut={(e) => (e.target.style.backgroundColor = 'transparent')}
                                                                    >
                                                                        {option}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={styles.outputHeading}>
                                            {item.method === 'DateOperations' ?
                                                handleDateOperation(index, item)
                                                :
                                                <Input
                                                    type='text'
                                                    placeholder="value"
                                                    value={item.value}
                                                    name='name'
                                                    onChange={(e) => handleChange('value', e?.target?.value, index, item.method)}
                                                    className={styles.valueSelect}
                                                />
                                            }
                                        </div>
                                        {index !== 0 && (
                                            <div className={styles.removeIcon}>
                                                <LucideIcon name="X" size="24" onClick={() => handleRemoveClick(index)} />
                                            </div>)}
                                    </div>
                                    {index === 0 &&
                                    <Button className={styles.addConditionButton} onClick={() => handleAddClick(1)}>
                                        Add
                                    </Button>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            <div className={styles.buttonWrapper}>
                <Button className={styles.saveAndCloseButton} onClick={()=>handleSave()}>Save & Close</Button>
                <Button className={styles.button} onClick={handleClose}>Close</Button>
            </div>
            <DropDownTwo
                showMenu={showMenu}
                onClose={() => setShowMenu(false)}
                dropDownOption={operations}
            />
        </PopupWrapper>
    )
}
export default BusinessLogicPopup;