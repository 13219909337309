import React from 'react'
import styles from './Signup.module.css'
import SignupForm from '../../../components/signupForm/layout/SignupForm.jsx';
import assets from '../../../assets/index'
const Signup = () => {
    const { mainImage, logo, secondaryImage } = assets;

    return (
        <>
            <div className={styles.bulbImageContainer}>
                <img className={styles.bulbImage} src={mainImage} />
            </div>
            <div className={styles.loginContainer}>
                <div className={styles.loginInnerContainer}>
                    <div className={styles.loginOuterWrapper}>
                        <div className={styles.loginInner}>
                            <div className={styles.title}>
                                <img src={logo} />
                            </div>
                            <div className={styles.loginHeading}>
                                Welcome to Ekyam App
                            </div>
                        </div>
                    </div>
                    <div className={styles.loginImage}>
                        <div className={styles.secondaryImageWrapper}>
                            <img className={styles.secondaryImage} src={secondaryImage} />
                        </div>
                    </div>
                    <div className={styles.loginForm}>
                        <SignupForm />
                    </div>
                    <div className={styles.ellipse1}></div>
                    <div className={styles.ellipse2}></div>
                </div>
            </div>
        </>
    )
}

export default Signup
