// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deleteDialog_modal__5gdcs {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.deleteDialog_modalActions__rYpZ7 {
    display: flex;
    gap: 1rem;
    justify-content: center;
    width: 100%;
}

.deleteDialog_cancelBtn__h\\+xFw {
    border-radius: 100px;
    border: 1px 0px 0px 0px;
    border: 1px solid #4470EC;
    cursor: pointer;
    font-size: 18px;
    opacity: 0px;
    padding: 8px 24px;
}

.deleteDialog_confirmBtn__RT0yH {
    align-items: center;
    background: linear-gradient(88.9deg, var(--color-lavender) 0.83%, var(--color-skyblue-300) 47.73%, var(--color-cyan) 99.56%);
    border-radius: 100px;
    border: none;
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 400;
    gap: 10px;
    height: 42px;
    justify-content: space-between;
    line-height: 17px;
    outline: none;
    padding: 16px 28px;
}

.deleteDialog_modal__5gdcs p {
    font-size: 1.3rem;
    color: red;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/deleteDialog/deleteDialog.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;IACZ,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,oBAAoB;IACpB,uBAAuB;IACvB,yBAAyB;IACzB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,4HAA4H;IAC5H,oBAAoB;IACpB,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,YAAY;IACZ,8BAA8B;IAC9B,iBAAiB;IACjB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,gBAAgB;AACpB","sourcesContent":[".modal {\n    align-items: center;\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n    height: 100%;\n    justify-content: center;\n    width: 100%;\n}\n\n.modalActions {\n    display: flex;\n    gap: 1rem;\n    justify-content: center;\n    width: 100%;\n}\n\n.cancelBtn {\n    border-radius: 100px;\n    border: 1px 0px 0px 0px;\n    border: 1px solid #4470EC;\n    cursor: pointer;\n    font-size: 18px;\n    opacity: 0px;\n    padding: 8px 24px;\n}\n\n.confirmBtn {\n    align-items: center;\n    background: linear-gradient(88.9deg, var(--color-lavender) 0.83%, var(--color-skyblue-300) 47.73%, var(--color-cyan) 99.56%);\n    border-radius: 100px;\n    border: none;\n    color: var(--color-white);\n    cursor: pointer;\n    display: flex;\n    font-family: Outfit;\n    font-size: 18px;\n    font-weight: 400;\n    gap: 10px;\n    height: 42px;\n    justify-content: space-between;\n    line-height: 17px;\n    outline: none;\n    padding: 16px 28px;\n}\n\n.modal p {\n    font-size: 1.3rem;\n    color: red;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `deleteDialog_modal__5gdcs`,
	"modalActions": `deleteDialog_modalActions__rYpZ7`,
	"cancelBtn": `deleteDialog_cancelBtn__h+xFw`,
	"confirmBtn": `deleteDialog_confirmBtn__RT0yH`
};
export default ___CSS_LOADER_EXPORT___;
