import { useEffect, useState } from 'react';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { useLocation, useNavigate } from 'react-router-dom';

export const useConnectorsFunc = () => {
    const [ defaultConnctors, setDefaultConnectors ] = useState([]);
    const [ connectedConnectors, setConnectedConnectors ] = useState([]);
    const [ defaultConnectorsError, setDefaultConnectorsError ] = useState('');
    const [ defaultConnctorsLoading, setDefaultConnectorsLoading ] = useState(true);
    const [ connectedConnectorsLoading, setConnectedConnectorsLoading ] = useState(true);
    const navigate = useNavigate();
    const { state } = useLocation();
    const organisation_id = (state && 'organisation_id' in state)?state?.organisation_id:null;

    const { callApi } = useCustomAxiosCall();

    if(!organisation_id){
        navigate('/organisation')
    }
    const getDefaultConnectors = async () => {
        setDefaultConnectorsLoading(true)
        const response = await callApi({
            uriEndPoint: {
                url: '/defaultconnector',
            }
        });
        if (response?.status === 200) {
            setDefaultConnectors(response?.data?.data || [])
        }else{
            console.log(response, 'error display')
            setDefaultConnectorsError(response?.data?.message || 'Something Went Wrong')
        }
        setDefaultConnectorsLoading(false)
    };

    const getConnectedConnectors = async () => {
        setConnectedConnectorsLoading(true)
        const response = await callApi({
            uriEndPoint: {
                url: `/organization/${ organisation_id }/connector`
            }
        });
        if (response?.status === 200) {
            setConnectedConnectors(response?.data?.data || [])
        }
        setConnectedConnectorsLoading(false)
    };

    const handleConnectConnector = () => getConnectedConnectors()

    useEffect(() => {
        getDefaultConnectors();
        getConnectedConnectors();
    }, [])

    return {
        defaultConnctors,
        connectedConnectors,
        defaultConnctorsLoading,
        connectedConnectorsLoading,
        defaultConnectorsError,
        handleConnectConnector
    };
}