import React from 'react'
import styles from './FormGroup.module.css'
import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func'
import ConfigMapperFieldV2 from '../../../views/ConfigMapperV2/layout/ConfigMapperFieldV2.jsx'
import { v4 as uuid } from 'uuid';
import Spinner from '../../../commonComponents/spinner/Spinner.jsx';

const FormGroup = ({ fieldData={}, formValues={}, onInputChange=()=>{}, loading }) => {
    // Import functions
    const { getDisplayName } = useConfigMapperFieldV2()

    // Assigning Variabless
    const fieldsData=[], nestedFieldsData=[]
    fieldData?.field_children?.map(field=>{
        if(field?.field_type === 'array'){
            nestedFieldsData.push(field)
        } else{
            fieldsData.push(field)
        }
    })

    // JSX
    return (
        <div className={`${ styles.row }`}>
            <div className={styles.formInputWrapper}>
                <div className={styles.labelWrapper}>
                    {getDisplayName(fieldData)}
                </div>
                <div className={`${ styles.inputWrapper }`}>
                    <div className={styles.inputGroupWrapper}>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <>
                                <div className={styles.inputGroup}>
                                    {fieldsData.map((field) => {
                                        let parentField = fieldData?.field_name;
                                        return (
                                            <ConfigMapperFieldV2
                                                displayInRow={true}
                                                fieldData={field}
                                                key={field?.field_id || uuid()}
                                                formData={formValues}
                                                onInputChange={onInputChange}
                                                parentFeild={parentField}
                                            />
                                        );
                                    })}
                                </div>
                                <div className={styles.nestedInputGroup}>
                                    {nestedFieldsData.map((field) => {
                                        let parentField = fieldData?.field_name;
                                        if (field?.field_type === 'array') {
                                            parentField = `${ parentField }#${ field.field_name }__array`;
                                        }
                                        return (
                                            <>
                                                <div
                                                    className={styles.fieldName}
                                                >
                                                    {getDisplayName(field)}
                                                </div>
                                                <div
                                                    className={
                                                        styles.arrayFieldsWrapper
                                                    }
                                                >
                                                    {field?.field_children.map(
                                                        (feildChildrenData) => (
                                                            <ConfigMapperFieldV2
                                                                displayInRow={
                                                                    true
                                                                }
                                                                fieldData={
                                                                    feildChildrenData
                                                                }
                                                                key={
                                                                    feildChildrenData?.field_id
                                                                }
                                                                formData={
                                                                    formValues
                                                                }
                                                                onInputChange={
                                                                    onInputChange
                                                                }
                                                                parentFeild={
                                                                    parentField
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormGroup
