import React, { useEffect, useState } from 'react';
import { flattenNestedData, getColumns } from '../../../../commonComponents/table/useTables.func';
import useCustomAxiosCallTwo from '../../../../hooks/useCustomAxiosCallTwo';

const collectiveDataSnowflake = (urlData = {}, filter, rowData = [], header = []) => {
    const uriEndPoint = { service: urlData.service };
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);
    const [ error, setError ] = useState('');
    const { callApi } = useCustomAxiosCallTwo();
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ totalRecords, setTotalRecords ] = useState(0);
    const [ headerData, setHeaderData ] = useState([]);
    const [ pageSize, setPageSize ] = useState(10)

    const filterValues = [
        { name: 'Default', value: '' },
        { name: 'Purchase Order', value: 'pos' },
        { name: 'Snowflake', value: 'snowflake' },
    ];
    let flattenedData = [], columns = [];

    const getTableData = async () => {
        setError('');
        setHeaderData([]);
        if (filter) {
            uriEndPoint.url = `${ urlData.url.filterUrl }/${ filter }`
        } else {
            uriEndPoint.url = urlData.url.baseUrl
        }
        if (filter === 'snowflake') {
            uriEndPoint.url = '/all-snowflake'
        }

        const newUrl = `${ uriEndPoint.url }?page=${ currentPage + 1 }&per_page=${ pageSize }`

        const response = await callApi({
            uriEndPoint: {
                ...uriEndPoint,
                url: newUrl
            }
        });
        if (response && response.status === 200 && !response?.data?.error) {
            setData(response?.data?.data || [])
            setHeaderData(response?.data?.header_data || [])
            setTotalRecords(response?.data?.total_count || 0)
        } else {
            setError(response?.data?.error || 'Something Went Wrong. Please Try Again Later');
            setLoading(false);
        }
    }

    columns = React.useMemo(() => getColumns(headerData, header), [ headerData, header ])
    flattenedData = React.useMemo(() => { const flattenedData = flattenNestedData(rowData, data, columns, {}); setLoading(false); return flattenedData }, [ data ])

    useEffect(() => {
        setLoading(true);
        getTableData();
    }, [ currentPage, pageSize, filter ]);

    return { columns, data: flattenedData, loading, error, currentPage, setPageSize, totalRecords, setCurrentPage, filterValues }
}
export default collectiveDataSnowflake;