import React, { useEffect } from 'react'
import styles from './popupWrapper.module.css';
import LucideIcon from '../lucideicon/LucideIcon.jsx';

function PopupWrapper({ isOpen, onClose, children, height, width }) {
    const handleOutsideModalClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e);
        }
    }
    useEffect(() => {
        // Add or remove the noScroll class
        if (isOpen) {
            document.body.classList.add(styles.noScroll);
        } else {
            document.body.classList.remove(styles.noScroll);
        }

        // Cleanup function to remove the class
        return () => {
            document.body.classList.remove(styles.noScroll);
        };
    }, [ isOpen ]);
    if (!isOpen) return null;
    const overlayStyles={};
    height?overlayStyles.height=height:null;
    width?overlayStyles.width=width:null;
    return (
        <div className={styles.modalOverlayWrapper} onClick={handleOutsideModalClick}>
            <div className={styles.modalOverlay} style={overlayStyles}>
                <div className={styles.header}>
                    <div className={styles.dotWrapper}>
                        <div className={styles.redDot} />
                        <div className={styles.orangeDot} />
                        <div className={styles.greenDot} />
                    </div>
                    <LucideIcon name="X" className={styles.closeIcon} onClick={(e)=>{onClose(e)}} />
                </div>
                <div className={styles.modal} >
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PopupWrapper;