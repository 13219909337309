import React from 'react';
import styles from './SignupForm.module.css';
import Button from '../../../commonComponents/button/Button.jsx';
import { EyeOff, Eye, CircleX } from 'lucide-react';
import Input from '../../../commonComponents/input/Input.jsx';
import { useSignupForm } from '../functionality/SignupForm.func';

const SignupForm = () => {
    const {
        formData,
        loading,
        showPassword,
        errorMessage,
        handleChange,
        handleLogin,
        handleClearUsername,
        handleTogglePassword

    } = useSignupForm()
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };
    return (
        <div className={styles.loginWrapper}>
            <form>
                <Input
                    type="text"
                    placeholder="Enter Email"
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    endIcon={<CircleX size={16} color='grey' onClick={handleClearUsername} />}
                    className={`${ styles.emailInput } ${ errorMessage[ 'email' ] ? styles.errorBorder : '' }`}

                />
                <div className={`${ styles.errorMessage } ${ errorMessage[ 'email' ] && styles.showErrorMessage }`}>{errorMessage[ 'email' ] || 'empty'}</div>
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Create Password"
                    value={formData.password}
                    name="password"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className={`${ styles.emailInput } ${ errorMessage[ 'password' ] ? styles.errorBorder : '' }`}
                    endIcon={showPassword ? (
                        <Eye size={16} color='grey' onClick={handleTogglePassword} />
                    ) : (
                        <EyeOff size={16} color='grey' onClick={handleTogglePassword} />
                    )}
                />
                <div className={`${ styles.errorMessage } ${ errorMessage[ 'password' ]  && styles.showErrorMessage }`}>{errorMessage[ 'password' ] || 'empty'}</div>
                <div className={styles.actionWrapper}>
                    <Button className={styles.button} onClick={handleLogin} >{loading ? 'Loading...' : 'Sign Up'}</Button>
                </div>
            </form>
            <div className={`${ styles.errorMessage } ${ errorMessage[ 'msg' ]  && styles.showErrorMessage }`}>{errorMessage[ 'msg' ] || 'empty'}</div>
        </div>
    );
};

export default SignupForm;