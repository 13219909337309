import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOrganisationData } from '../../../redux/organisation/Selector';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import styles from '../layout/queueTracker.module.css';
import { toast } from 'react-toastify';

export const useQueuTrackerFunc = (workflowId ) => {
    const [ open, setOpen ] = useState(false);
    const [ modalData, setModalData ] = useState({});
    const [ openDropdown, setOpenDropdown ] = useState(null);

    const [ tasksList, setTasksList ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ totalCount, setTotalCount ] = useState(0);

    const dropdownRef = useRef(null);

    const options = [ 'Retry', 'Cancel', 'Pause', 'Resume' ];

    const organizationData = useSelector(selectOrganisationData);
    const orgId = organizationData?.organisationData?.encrypt_id;

    const QUEUE_TRACKER_BASE_URL = process.env.REACT_APP_EKYAM_QUEUE_TRACKER_BASE_URL

    const handleFetchTasks = async (page = currentPage) => {
        try {
            if (page === 0) {
                setLoading(true);
            }
            const baseUrl = `${ QUEUE_TRACKER_BASE_URL }/tasks?page=${ page }&page_size=10`;
            const url = workflowId ? `${ baseUrl }&workflow_id=${ workflowId }` : baseUrl
            const response = await axios.get(url, {
                headers: {
                    'x-organisation-id': orgId
                }
            });

            setTasksList(response.data.data);  // Update task list
            setTotalCount(response.data.total_count);  // Update total count
        } catch (error) {
            console.error('Error fetching tasks: ', error);
        } finally {
            setLoading(false);  // Stop loading after the request finishes (success or failure)
        }
    };

    const handleShowDialog = (rowId, event) => {
        event.stopPropagation();
        const { pageX, pageY } = event;
        setOpenDropdown({ id: rowId, x: pageX, y: pageY });
    };

    const headerList = [
        { field: '_id', headerName: 'Task ID', flex: 1 },
        { field: 'task_name', headerName: 'Task Name', flex: 1, valueGetter: (params) => params.replaceAll('_', ' ') },
        {
            field: 'status', headerName: 'Status', flex: 1,
            renderCell: (params) => <p className={styles?.[ params.row.status ]}>
                {
                    params.row.status
                        .split('_') // Split the string into words
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
                        .join(' ')
                }
            </p>
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            flex: 1,
            valueGetter: (params) => {
                const date = new Date(params + 'Z');
                const localDate = date.toLocaleString();
                return localDate
            }
        },
        {
            field: 'progress',
            headerName: 'Progress (%)',
            flex: 1,
            valueGetter: (params) => params?.percentage || 'N/A'
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <div className={styles.actionCell}>
                    <LucideIcon
                        name="FilePenLine"
                        size="18"
                        color="#8B909A"
                        className={styles.editIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleShowDialog(params.row, e);
                        }}
                    />
                </div>
            ),
        },
    ];

    const dialogueHeadersList = [
        {
            field: 'id', headerName: 'Sr.No', valueGetter: (params) => params + 1
        },
        {
            field: 'task_name', headerName: 'Task Name', width: 200
        },
        {
            field: 'offset', headerName: 'Offset', width: 200,
        },
        {
            field: 'status', headerName: 'Status', width: 200
        },
        {
            field: 'error', headerName: 'Error Log', width: 200,
            valueGetter: (params) => params?.msg || 'N/A'
        }
    ];

    const taskWithIDs = tasksList.map((task) => ({ ...task, id: task._id }));
    const queuesStats = [
        {
            'id': 'total_tasks',
            'name': 'Total Tasks',
            'count': totalCount,
            'icon': 'BookMarked'
        },
        {
            'id': 'completed_tasks',
            'name': 'Completed Tasks',
            'count': tasksList.filter((task) => task.status === 'completed').length,
            'icon': 'BookMarked'
        },
        {
            'id': 'total_tasks_queued',
            'name': 'Total Tasks Queued',
            'count': tasksList.filter((task) => task.status === 'in_progress').length,
            'icon': 'SquareArrowLeft'
        },
        {
            'id': 'failed_tasks',
            'name': 'Failed Tasks',
            'count': tasksList.filter((task) => task.status === 'failed').length,
            'icon': 'Archive'
        }
    ];

    const rowClickHandler = async (params) => {
        if (params?.row?.data_flow_type === 'real_time') {
            const response = await axios.get(`${ QUEUE_TRACKER_BASE_URL }/task/${ params.row?.id }`, {
                headers: {
                    'x-org-id': orgId
                }
            });

            const modalObj = {
                'data': response?.data?.data,
                'task_name': response?.data?.task_name,
                'error': response?.data?.error,
                'offset': response?.data?.offset,
                'status': response?.data?.status,
                'data_flow_type': 'real_time',
                'id': response?.data?.id
            }
            setModalData(modalObj);
            setOpen(true);
            return

        }
        const filterData = tasksList?.find(obj => obj._id === params?.row?.id);
        setModalData(filterData);
        setOpen(true);

    };

    const handleRetry = async (task) => {
        try {
            const response = await axios.post(`${ QUEUE_TRACKER_BASE_URL }/retry/${ task.id }`, null, {
                headers: {
                    'x-organisation-id': orgId
                }
            });

            if (response.status === 200) {
                toast.success('Task Retrying Successfully')
                await handleFetchTasks()
            }
        } catch (error) {
            console.log(error, 'ERRORRR');
            toast.error(error.response.data?.msg || 'Something Went Wrong!!')

        }
    };

    const handleToggleStatus = async (task, status) => {
        try {
            const response = await axios.post(`${ QUEUE_TRACKER_BASE_URL }/toggle-status/${ task.id }`, {
                status: status
            }, {
                headers: {
                    'x-organisation-id': orgId
                }
            });

            if (response.status === 200) {
                toast.success('TASK STATUS TOGGLED SUCCESSFULLY')
                await handleFetchTasks()
            }
        } catch (error) {
            console.log(error, 'ERRORRR');
            toast.error(error.response.data?.msg || 'Something Went Wrong!!')
        }
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleCloseDropdown = () => {
        setOpenDropdown(null);
    };

    useEffect(() => {
        handleFetchTasks();
    }, [ currentPage ]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdown(null);
            }
        };

        if (openDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ openDropdown ]);

    return {
        open,
        modalData,
        handleRetry,
        handleToggleStatus,
        queuesStats,
        taskWithIDs,
        headerList,
        dialogueHeadersList,
        options,
        loading,
        rowClickHandler,
        tasksList,
        onClose,
        openDropdown,
        dropdownRef,
        handleCloseDropdown,
        setCurrentPage,
        totalCount
    };
};
