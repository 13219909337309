import React from 'react';
import styles from './pieChartLabels.module.css'
import Typography from '../../typography/Typography.jsx';
import PieChart from '../pieChart/PieChart.jsx';

const PieChartLabels = ({ data, chartOnLeft=false })=>{
    return <div className={styles.barChartWrapper}>
        <div className={styles.chartHeader}>
            <Typography variant='heading'>{data?.title}</Typography>
        </div>
        <div className={styles.barChart} style={chartOnLeft ? { flexDirection:'row-reverse' }:{}}>
            <div className={styles.leftSideContent}>
                {data?.data?.map((obj, index) =>
                    <div key={index} className={styles.labelWrapper}>
                        <span className={styles.colorBox} style={{ backgroundColor: data?.colors[ index ] }}></span>
                        <div className={styles.label}>{obj?.label} {obj?.value}%</div>
                    </div>
                )}
            </div>
            <PieChart
                data={data?.data}
                colors={data?.colors}
            /> </div>
    </div>
}

export default PieChartLabels;