import React from 'react';
import AppLoader from '../../../appLoader';
import styles from './orderDashboard.module.css';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import data from '../../../dummyData/OrderDashboard.json';
import StatCounterIcon from '../../../components/widgets/statCounterIcon/layout/StatCounterIcon.jsx';
import MultiBarChart from '../../../commonComponents/charts/barChart/BarChart.jsx';
import DataGridTable from '../../../components/widgets/dataGrid/layout/DataGridTable.jsx';
import PieChartLabels from '../../../commonComponents/charts/pieChartLabels/PieChartLabels.jsx';
import useOrderDashboard from '../functionality/OrderDashboard.func';
import Spinner from '../../../commonComponents/spinner/Spinner.jsx';
import Orders from '../../../components/orders/layout/Orders.jsx';

const OrderDashboard =()=>{
    const barChart = data?.charts?.barChart;
    const { widgetData, widgetLoading, widgetError } = useOrderDashboard({ url:'/widget-count', service:'collectivedata', type: 'webshop', fetch_by: 'type' });
    return <AppLoader>
        <div className={styles.adminDashboard}>
            <Typography variant='gradient'>Customer Order Details</Typography>
            <Orders tribe='webshop' title='Webshop Orders'/>
            <Orders tribe='3pl' title='3PL Orders'/>
            {widgetLoading
                ? <Spinner height={'25vh'}/>
                : widgetError?<Typography variant='error'>{widgetError}</Typography>
                    :<div className={styles.statWrapper}>
                        <StatCounterIcon id= "order_pending" statName="Order Pending" count="150" iconName="BarChartBig" percentage="20" iconColor="#FA5A7D" />
                        <StatCounterIcon id="order_shipped" statName="Order Shipped" count={widgetData?.shipped_orders?.count || 0} iconName="LineChart" percentage="20" iconColor="#3CD856" height="100px" />
                        <StatCounterIcon id= "order_delivered" statName="Order Delivered" count="1432" iconName="Truck" percentage="-20" iconColor="#BF83FF" />
                        <StatCounterIcon id= "order_returned" statName="Order Returned" count="80" iconName="HandCoins" percentage="20" iconColor="#FF947A" />
                        <StatCounterIcon id= "order_cancelled" statName="Order Cancelled" count={widgetData?.cancelled_orders_widget?.cancelled_this_month_count || 0} iconName="HandCoins" percentage="20" iconColor="#7AAFFF" height="100px"/>
                    </div>
            }
            { <div className={styles.chartContainer}>
                <div className={styles.pieChartContainer}>
                    <PieChartLabels data={data?.charts?.pieChart} title='Delivery Method' chartOnLeft={true}/>
                </div>
                <div className={styles.lineChartContainer}>
                    <MultiBarChart
                        title='Order Status'
                        height={200}
                        width={600}
                        data={barChart?.data}
                        labels={barChart?.labels}
                        colors={barChart?.colors}
                    />
                </div>
            </div> }
            {/* <StackedBarChart /> */}
            <DataGridTable title={'Shipment Delays'} rowsData={data?.shipment_returns?.rows} headerList={data?.shipment_returns?.header} totalRows={data?.shipment_returns?.rows?.length}/>

        </div>
    </AppLoader>
}
export default OrderDashboard;