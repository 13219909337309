import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    workflowData:[]
};

const workflow = createSlice({
    name: 'workflow',
    initialState,
    reducers: {
        setWorkflowData: (state, action) => {
            const newData = action.payload;
            const existingIndex = state.workflowData.findIndex(
                item => Object.keys(item)[ 0 ] === Object.keys(newData)[ 0 ]
            );

            if (existingIndex > -1) {
                state.workflowData[ existingIndex ] = newData;
            } else {
                state.workflowData.push(newData);
            }
        }
    }
});

export const workflowReducer = workflow.reducer;
export const { setWorkflowData } = workflow.actions;