// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders_headerWrapper__HO\\+hj {
    margin-bottom: 1rem;
}

.orders_header__OhKN6 {
    margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/orders/layout/orders.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".headerWrapper {\n    margin-bottom: 1rem;\n}\n\n.header {\n    margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": `orders_headerWrapper__HO+hj`,
	"header": `orders_header__OhKN6`
};
export default ___CSS_LOADER_EXPORT___;
